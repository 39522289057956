import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var deDE = {
  auth: {
    routes: {
      permissions: {
        desc: 'Verwalten von Nutzern, Rollen und Regeln',
        title: 'Berechtigungen'
      }
    }
  }
};
var enGB = {
  auth: {
    title: 'Auth',
    desc: 'Auth module',
    routes: {
      permissions: {
        title: 'Permissions',
        desc: 'Manage accounts, roles and rules'
      }
    }
  }
};
var esES = {
  auth: {
    routes: {}
  }
};
var frFR = {
  auth: {
    routes: {}
  }
};
const AUTH_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGB
  },
  [SystemLanguage['es-ES']]: {
    ...esES
  },
  [SystemLanguage['de-DE']]: {
    ...deDE
  },
  [SystemLanguage['fr-FR']]: {
    ...frFR
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { AUTH_TRANSLATIONS };
